// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      file(relativePath: { eq: "kxp-logo-dark-bg.png" }) {
        childImageSharp {
          fixed (height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header
      style={{
        background: `rgba(10, 10, 25, 255)`,
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 986,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <Img fixed={data.file.childImageSharp.fixed} alt="KXP" />
      </div>
    </header>
  )
}
